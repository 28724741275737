import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`The accordion component delivers large amounts of content in a small space through progressive disclosure. That is, the user gets key details about the underlying content and can choose to expand that content within the constraints of the accordion. Accordions work especially well on mobile interfaces or whenever vertical space is at a premium.`}</p>
    <p>{`Avoid “nested” accordions—that is, collapsible content within collapsible content. This type of pattern goes against UX best practices.`}</p>
    <p>{`The Carbon accordion allows for multiple sections to be expanded simultaneously.`}</p>
    <p>{`A chevron is used to indicate the “expand/collapse” action, though the entire header area is clickable for the same action.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.05434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACC0lEQVQoz6VSy64hURQ9pWjldnmUUqW47ki66fYLXvEYegTxCCESAwNDP+AHfIaEgYFfEBJChHgTj8RUJEZG9K5K6+bmdnpwd7Ky9qk6Z52z915ovV4jPtLptMB2ux1ZLBZ0u93QeDxGy+US8Xv+Bz7ujEqlEvpsdDod9EeVJEkhN5vNtNFofDOZTCy/5l95x33d6/Ww7XaLrVYrbLPZYJfLBet2u8J/+PbwTIh8Pq8OBoNvkUhEn8lk2GQyyaVSKQHRaFSfzWbZ4/EoBVHZYrEgZrMZ0Wg0ZPcLBa1HQTgkdzqdrNvt1gAYl8vF2Gw2DvpLQi/FrVZLAocxAH69XvF+vy8pl8vSJ0E+DAaDwFD6i1qt5iiKYgHMb/C5RqVSqZVKJZCKBqZEItGXxx5Wq9W/C51OJzBN0xqGYX4CvgO+vWMTwAj4AZfS74dyPp/R8zMhfD6fDGyjgLLlUDLpcDj4Fsi9Xu9Xj8dD8rnValUUi0Wh9OFwKDocDqLpdPrxUGCjAvrIwTBYGAwXj8e1fB6LxbQwGCaRSGjr9foLiEnAo1IQImq12r+HksvlKL/f/xoKhXTw2tdAIKAPh8McnxcKBWo0GhHNZpOAAUnBMtLJZEJUKpWPBcFPCErA2+22GGwhBm+JwawCDwYDMQjgp9MJ3+12OJSGwzmB5/M5Dr58bt/9hs/Efr8X+Bc4VyQY0kw1nAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "accordion example",
            "title": "accordion example",
            "src": "/static/e624fadd33b47ca4d5ea51a963234633/fb070/accordion-usage-1.png",
            "srcSet": ["/static/e624fadd33b47ca4d5ea51a963234633/d6747/accordion-usage-1.png 288w", "/static/e624fadd33b47ca4d5ea51a963234633/09548/accordion-usage-1.png 576w", "/static/e624fadd33b47ca4d5ea51a963234633/fb070/accordion-usage-1.png 1152w", "/static/e624fadd33b47ca4d5ea51a963234633/c3e47/accordion-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      